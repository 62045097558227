<template>
    <div class="d-flex flex-column align-end">
        <div 
            v-on:click="value.is_show = false" 
            class="close-calendar mb-2 d-flex align-center justify-center">
            <img :src="$store.state.icons.icons['RedCross']">
        </div>
        <div
            class="my-calendar d-flex justify-center align-center flex-column"
            >
            <v-date-picker
                light
                landscape="landscape"
                :min="min"
                color="#2974FF"
                v-model="value.date"
                ></v-date-picker>

            <div class="time-select">
                <p class="heading-sf16 mb-2">Requested delivery date & time</p>
                <p class="heading-mf16 mb-4">{{ this.value.requested_date }} / {{ timeOfDelivery }}</p>
                <div class="d-flex align-center">
                    <div class="d-flex flex-column justify-center">
                        <div class="d-flex align-start mb-1">
                            <p class="heading-rf13">Start time</p>
                        </div>
                        <div class="d-flex align-center">
                            <v-select
                                class="time-picker"
                                :items="startTimes"
                                label="Start time"
                                height="44"
                                hide-details
                                dense
                                light
                                solo
                                v-model="value.start_time"
                                ></v-select>
                        </div>
                    </div>

                    <div class="d-flex flex-column justify-center ml-auto">
                        <div class="d-flex align-start mb-1">
                            <p class="heading-rf13">End time</p>
                        </div>
                        <div class="d-flex align-center">
                            <v-select
                                class="time-picker"
                                :items="endTimes"
                                label="End time"
                                height="44"
                                hide-details
                                dense
                                light
                                solo
                                v-model="value.end_time"
                                ></v-select>
                        </div>
                    </div>
                </div>
                   
                <!-- <div class="d-flex align-center mt-4">
                    <div
                        v-on:click="value.time = 'M'" 
                        class="time-box d-flex align-center justify-center"
                        :class="{'time-selected': value.time === 'M'}"
                        >
                        Morning
                    </div>

                    <div 
                        v-on:click="value.time = 'N'" 
                        class="time-box d-flex align-center justify-center ml-auto"
                        :class="{'time-selected': value.time === 'N'}"
                        >
                        Noon
                    </div>
                </div>
                    
                <div class="d-flex mt-4">
                    <div 
                        v-on:click="value.time = 'A'" 
                        class="time-box d-flex align-center justify-center"
                        :class="{'time-selected': value.time === 'A'}"
                        >
                        Afternoon
                    </div>

                    <div 
                        v-on:click="value.time = 'L'" 
                        class="time-box d-flex align-center justify-center ml-auto"
                        :class="{'time-selected': value.time === 'L'}"
                        >
                        Last Stop
                    </div>
                </div> -->
                
            </div>   
        </div>
    </div>
</template>

<script>
export default {
    props:{
        value: Object
    },

    data(){
        return {
            item:'',
            start_time:'',
            end_time:'',
            times: [
                '05:00:00',
                '06:00:00',
                '07:00:00',
                '08:00:00',
                '09:00:00',
                '10:00:00',
                '11:00:00',
                '12:00:00',
                '13:00:00',
                '14:00:00',
                '15:00:00',
                '16:00:00',
                '17:00:00',
                '18:00:00',
                '19:00:00',
                '20:00:00',
                '21:00:00',
            ]
        }
    },

    computed:{
        min(){
            return new Date().toISOString().slice(0, 10);
        },

        startTimes(){
            return this.times;
        },

        endTimes(){
            return this.times.filter(time => {
                return time > this.value.start_time;
            })
        },

        timeOfDelivery(){
            let time = this.value.requested_time;
            
            if(time === 'M'){
               return 'Morning';
            }
            else if(time === 'N'){
                return 'Noon';
            }
            else if(time === 'A'){
                return 'Afternoon';
            }
            else if(time === 'L'){
                return 'Last Stop';
            }
            else if(time === 'AS'){
                return 'As soon as possible';
            }
        },

        // startTimes(){
        //     if(this.value.time === 'M'){
        //         return this.times.filter(time => {
        //             return time >= '05:00:00' &&  time <= '09:00:00';
        //         })
        //     }
        //     else if(this.value.time === 'N'){
        //         return this.times.filter(time => {
        //             return time >= '10:00:00' &&  time <= '11:00:00';
        //         })
        //     }
        //     else if(this.value.time === 'A'){
        //         return this.times.filter(time => {
        //             return time >= '12:00:00' &&  time <= '16:00:00';
        //         })
        //     }
        //     else if(this.value.time === 'L'){
        //         return this.times.filter(time => {
        //             return time >= '17:00:00' &&  time <= '20:00:00';
        //         })
        //     }
        //     else{
        //         return [];
        //     }
        // },

        // endTimes(){
        //     if(this.value.time === 'M'){
        //         return this.times.filter(time => {
        //             return time > this.start_time &&  time <= '10:00:00';
        //         })
        //     }
        //     else if(this.value.time === 'N'){
        //         return this.times.filter(time => {
        //             return time > this.start_time &&  time <= '12:00:00';
        //         })
        //     }
        //     else if(this.value.time === 'A'){
        //         return this.times.filter(time => {
        //             return time > this.start_time &&  time <= '17:00:00';
        //         })
        //     }
        //     else if(this.value.time === 'L'){
        //         return this.times.filter(time => {
        //             return time > this.start_time &&  time <= '21:00:00';
        //         })
        //     }
        //     else{
        //         return [];
        //     }
        // }
    },

    beforeDestroy() {
        if(this.value.end_time > '17:00:00'){
            this.value.time = 'L';
        }
        else if(this.value.end_time >= '12:00:00'){
            this.value.time = 'A';
        }
        else if(this.value.end_time >= '10:00:00'){
            this.value.time = 'N';
        }
        else  if(this.value.end_time >= '05:00:00'){
            this.value.time = 'M';
        }

        if(this.value.date === '' || this.value.time === ''){
            this.value.is_validate = false;
        }
        else{
            this.value.is_validate = true;
        }
    }
}
</script>

<style lang="scss">
.my-calendar {

    background: #FFFFFF;
    box-shadow: 0 22px 54px 0 rgba(0,0,0,0.20);
    border-radius: 8px !important;
    height: 520px;
    width: 360px;
    z-index: 9;

    .v-picker__title,
    .v-picker__title--landscape {
        display: none;
    }

    .v-card {
        box-shadow: 0px 0px 0px !important;
        border-radius: 8px !important;
    }
    .v-picker__body{
        margin: 0px 0px 0px 0px !important;
        width: 312px !important;
        padding: 24px 0px 0px 0px;
    }

    .v-btn__content {
        font-family: Inter-Regular !important;
        font-size: 14px !important;
        color: rgba(0,0,0,0.87) !important;
    }

    .v-btn:before {
        background: #FFFFFF !important;
        border: none;
        border-radius: 4px !important;  
    }

    .v-btn--rounded{
        border-radius: 4px !important;   
    }

    .v-btn--active {
        background: #FFFFFF !important;
        border: 2px solid #2974FF !important;
        border-radius: 4px !important; 
    }

    .v-btn--disabled {
        .v-btn__content {
            color: #A3A3A3 !important;
        }
    }

    .v-date-picker-table > table > thead > tr > th {
        font-family: Inter-Regular;
        font-size: 14px;
        color: #55647B;
        text-align: center;
        padding: 0px 0px 16px 0px;
    }

    .v-btn {
       height: 36px !important;
       width: 36px !important;
    }

    .v-date-picker-header__value,
    .v-btn--icon{
        color: #414141 !important;
        font-family: Inter-SemiBold;
        font-size: 18px;
       
    }

    .v-date-picker-table,
    .v-date-picker-header {
        padding: 0px;
        padding-bottom: 16px;
    }

    .v-date-picker-header__value button:hover {
        color: #414141 !important;
    }

    .time-select{
        border-top: 1px solid #EBEEF6;
        height: 300px;
        width: 360px;
        padding: 24px;
    }

    .time-box {
        background: #FFFFFF;
        border: 2px solid #DAE6F7;
        border-radius: 4px;
        height: 48px;
        width: 148px;
        font-family: Inter-SemiBold;
        font-size: 16px;
        color: #212121;
        cursor: pointer;
    }

    .time-selected {
        border: 2px solid #2974FF;
    }

    .time-picker{
        border: 2px solid #DAE6F7;
        max-width: 148px;
        min-width: 148px;
        margin: 0px !important;
    }

    .time-picker:hover{
        border: 2px solid #2974FF !important;
    }

    .time-picker label {
        background: #FFFFFF !important;
        font-family: Inter-SemiBold !important;
        font-size: 16px !important;
        color: #212121 !important;
        left: auto !important;
    }

    .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
        box-shadow: none !important;
    }

    .v-text-field .v-input__prepend-inner, .v-text-field .v-input__append-inner {
        display: none !important;
    }

    .v-select__slot,
    .v-select__selections{
        text-align: center !important;
        justify-content: center !important;
        background: #FFFFFF !important;
        font-family: Inter-SemiBold !important;
        font-size: 16px !important;
        color: #212121 !important;
        left: auto !important;
    }

    .v-select__selections > input {
        display: none !important;
    }

}

.close-calendar {
    background: #FFFFFF;
    box-shadow: 0 18px 50px 0 rgba(0,0,0,0.20);
    border-radius: 8px;
    height: 46px;
    width: 46px;
    cursor: pointer;

    img{
        height: 16px;
        filter: brightness(0) saturate(100%) invert(17%) sepia(1%) saturate(2954%) hue-rotate(9deg) brightness(107%) contrast(80%);
    }
}


</style>